<form name="aspnetForm" method="post" action="./paymentmethod.aspx" id="aspnetForm">
    <div>
        <input type="hidden" name="ctl00_ScriptManager1_HiddenField" id="ctl00_ScriptManager1_HiddenField" value="">
        <input type="hidden" name="__EVENTTARGET" id="__EVENTTARGET" value="">
        <input type="hidden" name="__EVENTARGUMENT" id="__EVENTARGUMENT" value="">
        <input type="hidden" name="__VIEWSTATE" id="__VIEWSTATE"
            value="/wEPDwUKLTE4OTEwMTc0OQ9kFgJmD2QWAgIED2QWBgIFDw8WBB4OVGltZW91dE1pbnV0ZXMIAACgQR4VQWJvdXRUb1RpbWVvdXRNaW51dGVzCAAAmEEWAh4Fc3R5bGUFDWRpc3BsYXk6bm9uZTtkAgsPZBYCZg8WAh4IZGlzYWJsZWRkZAINDxYEHgRocmVmBR1tYWlsdG86TGluZGEuU3RpbGVzQGF6ZG9hLmdvdh4JaW5uZXJodG1sBRZMaW5kYS5TdGlsZXNAYXpkb2EuZ292ZBgCBR5fX0NvbnRyb2xzUmVxdWlyZVBvc3RCYWNrS2V5X18WBAUjY3RsMDAkTG9naW5WaWV3MSRMb2dpblN0YXR1czEkY3RsMDEFI2N0bDAwJExvZ2luVmlldzEkTG9naW5TdGF0dXMxJGN0bDAzBRpjdGwwMCRtYWluQ29udGVudCRyYWRpb1BSRAUdY3RsMDAkbWFpbkNvbnRlbnQkcmFkaW9DUkVESVQFEGN0bDAwJExvZ2luVmlldzEPD2QCAWQPcKeywNDx9BYswY1A0ka6RVg+PijXlIz56PcOnJJhUg==">
    </div>




    <script
        src="/Donate/WebResource.axd?d=JbDTahrdrbkLlH8o_JdgA9ikMXEcF_EJc6Z3OJN6RSNN-yHnEbHAbSAKxANE1xGypJ82kpWTD6VsrrsyxV6Vf9C_FJOG4AJyiBbuX3BUJS01&amp;t=637814401746327080"
        type="text/javascript"></script>


    <script type="text/javascript">
        //<![CDATA[
        function CallServer(arg, context) { WebForm_DoCallback('ctl00$Timeout1', arg, ReceiveServerData, context, null, false); }//]]>
    </script>

    <script
        src="/Donate/ScriptResource.axd?d=dsj5ACFzcBEGRMZL6hrpaW7FP-xanaiRs0mQ6-JSUvXhX2gbyyaij3ZaTyoI5ZisZTuU8txuqE5eq-r5IkSotnHDh0WeqFM6XQlKO-qdy-bgqc8hFT0MHIc-Kj48AjuNYEGvXvPOVfnTOfkyFrzEWg2&amp;t=ffffffffcda39869"
        type="text/javascript"></script>
    <script type="text/javascript">
        //<![CDATA[
        if (typeof (Sys) === 'undefined') throw new Error('ASP.NET Ajax client-side framework failed to load.');
//]]>
    </script>

    <script
        src="/Donate/ScriptResource.axd?d=jAL1FnEauOs4rW5Ys2pIBxXBQy2P0SkXnHvsHRj1hvOgIqtEIx9vDSrcAQYBN5cYs1YBsK3u60iB96dzyg_rDSXlFG56oI_imNFQR8AITrJSzeLMrEnt6X0_LKorse4HuCsn3BHqY1LJYDh9VOPOkQ2&amp;t=ffffffffcda39869"
        type="text/javascript"></script>
    <script
        src="/Donate/WebResource.axd?d=r7RIgbZ8ZkVe0Zi-2-FAckt9JkvXl-CVNAJst1Soq1clrzIt3UlVIv9t53qtNlI9vI45xoTsMp7Z5qfl-1iey3fl_FSKUJczdqO3jtaDHX2xeXFruw6cROHRntROfHfT0&amp;t=636656263693116770"
        type="text/javascript"></script>
    <div>

        <input type="hidden" name="__VIEWSTATEGENERATOR" id="__VIEWSTATEGENERATOR" value="0FCEC931">
        <input type="hidden" name="__EVENTVALIDATION" id="__EVENTVALIDATION"
            value="/wEdAAag3UJLFlpxxlDllc3Y4U5m+7XkQu4d7nK4EBiRem09SbFyKm0mYQYHb/C5hlOszliJTpntM5a/WWhXp3AC0QvV7RsoFPePPGAQToK/broqiFigsWkOUnsAXGqJDgwaK6fQ7naRDSL4/JG1J4p/qGzz1S5ZVdAhZlW8L7Nra6qkqQ==">
    </div>
    <script type="text/javascript">
        //<![CDATA[
        Sys.WebForms.PageRequestManager._initialize('ctl00$ScriptManager1', 'aspnetForm', [], [], [], 90, 'ctl00');
//]]>
    </script>

    <div>
        <span id="dirtyForm" display="none"></span>
        <span id="ctl00_Label1"></span>

    </div>
    <div id="wrapper">
        <div id="header">
            <h1><a href="default.aspx">Arizona State Employee Charitable Campaign</a></h1>
            <img id="ctl00_imgHeader" src="assets/img/secc.png" alt="Arizona State Employee Charitable Campaign"
                style="border-width:0px;">
        </div>
        <div id="nav">

            <ul>
                <li>
                    <a id="ctl00_LoginView1_LoginStatus1" title="Logout"
                    href="contributor/auth/dologout" >LOGOUT</a>
                </li>
                <!--<li><a href="feedback.aspx" title="Feedback">FEEDBACK</a></li>//-->
                <li><a href="assets/doc/Instructions.pdf" title="Instructions" target="_blank">INSTRUCTIONS</a></li>
                <li><a  href="contributor/welcome"title="Home">HOME</a></li>
            </ul>

        </div>
        <div id="container">

            <div id="container">
                <h2>Payment Method</h2>
                <div class="formcopy">
                    <p>Choose one of the payment methods below.</p>
                    <p>If you wish to pay with cash, or a paper check, you must use the <strong><a
                                href="assets/doc/PledgeCard.pdf" target="_blank" id="A2">printed pledge
                                form</a></strong>.</p>
                    <p>Contribution total amount due is <strong>{{this.seccdonateService.annualAmount | currency}}</strong></p>
                </div>


                <input value="PRD" name="paymentMethod" type="radio" id="paymentMethod_PRD" [(ngModel)]="paymentMethod"
                    [disabled]="!isPayrollEnabled"> <strong>Payroll Deduction:</strong> Amount is divided into 26 equal
                paycheck deductions<br>
                <div *ngIf="!isPayrollEnabled">
                    <font color="red">{{payrollDisabledReason}}</font>
                </div>
                <input value="CC" name="paymentMethod" type="radio" id="paymentMethod_CREDIT"
                    [(ngModel)]="paymentMethod"> <strong>Credit / Debit Card:</strong> One-time payment for full
                amount<br>

                <br>
                <div id="ctl00_mainContent_Message" class="reqd"></div>
                <br>
                <p style="text-align:center">
                    &nbsp;<button name="backbutton" id="backbutton" (click)="goBack()">Back</button>
                    <button name="ContinueButton" id="ContinueButton" (click)="continue()">Continue</button>
                </p>
            </div>


        </div>
        <div id="footer">
            <table width="100%">
                <tbody>
                    <tr>
                        <td width="47%"><a href="mailto:Linda.Stiles@azdoa.gov" id="ctl00_aDirector"
                                target="_blank">Linda.Stiles@azdoa.gov</a>
                        </td>
                        <td>
                            <a href="default.aspx">SECC Home</a>
                        </td>
                        <td style="text-align: right; font-weight: bold;">
                            Version: 2.0.0
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>


    <script type="text/javascript">
        //<![CDATA[
        (function () { var fn = function () { $get("ctl00_ScriptManager1_HiddenField").value = ''; Sys.Application.remove_init(fn); }; Sys.Application.add_init(fn); })();
        WebForm_InitCallback(); Sys.Application.add_init(function () {
            $create(TSC.Timeout.Timeout, { "aboutToTimeoutMinutes": 19, "clientId": "ctl00_Timeout1", "countDownSpanID": "tscCounter", "dirtyFormSpanID": "dirtyForm", "displayButton": false, "resetSessionOnAsyncPostback": true, "timeoutMinutes": 20, "timeoutURL": "Login.aspx" }, null, null, $get("ctl00_Timeout1"));
        });
//]]>
    </script>
</form>


<div class="ui-dialog ui-widget ui-widget-content ui-corner-all  ui-draggable ui-resizable" tabindex="-1" role="dialog"
    aria-labelledby="ui-dialog-title-ctl00_Timeout1"
    style="display: none; position: absolute; overflow: hidden; z-index: 1000; outline: 0px;">
    <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix" unselectable="on"><span
            class="ui-dialog-title" id="ui-dialog-title-ctl00_Timeout1" unselectable="on">Session Expiring</span><a
            href="#" class="ui-dialog-titlebar-close ui-corner-all" role="button" unselectable="on"><span
                class="ui-icon ui-icon-closethick" unselectable="on">close</span></a></div><span id="ctl00_Timeout1"
        style="" class="ui-dialog-content ui-widget-content">
        <p>
            <span class="ui-icon ui-icon-alert" style="float: left; margin: 1px 10px 20px 0;">
            </span>Your session will expire in.
        </p>
        <span id="tscCounter"></span>&nbsp;seconds
        <p>
            Click <b>OK</b> to continue your session.</p>
    </span>
    <div class="ui-resizable-handle ui-resizable-n" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-e" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-s" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-w" unselectable="on"></div>
    <div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se ui-icon-grip-diagonal-se"
        unselectable="on" style="z-index: 1001;"></div>
    <div class="ui-resizable-handle ui-resizable-sw" unselectable="on" style="z-index: 1002;"></div>
    <div class="ui-resizable-handle ui-resizable-ne" unselectable="on" style="z-index: 1003;"></div>
    <div class="ui-resizable-handle ui-resizable-nw" unselectable="on" style="z-index: 1004;"></div>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix"><button type="button"
            class="ui-state-default ui-corner-all">Ok</button></div>
</div>
