import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';
import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import { NonprofitcontributeComponent } from '../nonprofitcontribute/nonprofitcontribute.component';
import { SearchParams } from '../../models/searchparams.model';
import { SeccdonateService } from '../../services/seccdonate.service';
import { ConfirmmodalComponent } from '../confirmmodal/confirmmodal.component';

import { PageEvent } from '@angular/material/paginator';
import { NotyouComponent } from '../notyou/notyou.component';



@Component({
  selector: 'app-designations',
  templateUrl: './designations.component.html',
  styleUrls: ['./designations.component.css']
})
export class DesignationsComponent implements OnInit {

  constructor(public seccdonateService: SeccdonateService,
    private router: Router,
    public dialog: MatDialog,
    private sessiontimeoutService : SessiontimeoutService
  ) { }

  spinner : any;

  private nonProfitSearchSubscription: Subscription = new Subscription();
  private countiesSubscription: Subscription = new Subscription();
  private svcsProvidedSubscription: Subscription = new Subscription();
  private contributerSubscription : Subscription = new Subscription();
  private configSubscription : Subscription = new Subscription();


  nonProfits: Array<any>; //Array for search results. Corresponds to Java object [NonProfitDisplay] from API.

  searchParams: SearchParams;
  contributions: string;
  maxContributions: string;
  annualAmount: number;
  payFactor: number;  // x26 for payment every pay period; x1 for one time annual payment
  designationWeek : number; // to be calculated

  lineItemsTotal: number; //sum of line item contributions displayed on UI (not same as annual amount)

  countiesList: any = {};
  svcsProvidedList: any = {};

  npcontributions: Array<NonprofitcontributeComponent>; //UI array to which user adds contributions to various orgs

  @ViewChildren('childNP')
  npQueryList: QueryList<NonprofitcontributeComponent>; //for querying all child components tagged with 'childNP' in above array



  //MatPaginator Inputs
  length: number;
  pageSize: number;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  startIdx: number;
  endIdx: number;


  //  MatPaginator Output
  pageEvent: PageEvent;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }



  ngOnInit() {

    this.spinner = this.seccdonateService.startSpinner('spinarea-secc');  //Spin it, baby !

    this.sessiontimeoutService.reset();
    this.pageEvent = new PageEvent();  // Initialize paginator with 'dummy' event and default values because no actual page event has happened yet from a mouse click.
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 5;

    this.getContributor(''); //remove ein from function signature since not required

    this.npcontributions = new Array();
    this.annualAmount = 0;
    this.npcontributions = this.seccdonateService.npDonations;
    this.updateOrderTotal();

    this.searchParams = new SearchParams();
    this.calcDesignationWeek();
    this.getCounties();
    this.getServicesProvided();
    this.searchNonProfit();

    //NOT Edit mode
    if (!this.isEditMode()) {
      this.annualAmount = 0;
      this.payFactor = 26;// Intialize to Pay Period
    } else {
      //Edit mode
      this.lineItemsTotal = this.calcTotal(); //TEST
      this.payFactor = this.seccdonateService.payFactor;
    }

  }


  /*
   * Used only in Edit mode. When user returns to Designations page showing NonProfit search results (with multiple pages)
   * ngAfterViewChecked() is called after EVERY page scroll. This is important. THe checked nonProfits could be
   * several pages down from page 1 , so checkedSelectedNonProfits() would have to be called after every page scroll. If
   * one or more NonProfits match with previously selected ones they will get checked.
  */
  ngAfterViewChecked() {
    if (this.isEditMode()) {
      this.checkSelectedNonProfits();
    }
  }


  /* Add or remove designation from the list
  */
  toggleDesignation(nonprofit) {

    const npCheckBox = document.getElementById(nonprofit.nonProfitId) as HTMLInputElement;

    if (npCheckBox.checked == true) { //add

      let np: NonprofitcontributeComponent = new NonprofitcontributeComponent(this.seccdonateService, this.sessiontimeoutService);
      np.nonProfitId = nonprofit.nonProfitId;
      np.nonProfitTitle = nonprofit.nonProfitTitle;
      np.code = nonprofit.stCode;
      np.notifyRecipient = false;
      np.participantYear = nonprofit.participantYear;
      np.clickAndGive = true; //update 9/12/2024
      np.week = "" + this.designationWeek;

      //Save index
      var length: number = this.npcontributions.length;
      np.index = length; //TODO is this needed ?

      //copy other stuff as necessary
      this.npcontributions.push(np);
    }
    if (npCheckBox.checked == false) { //remove

      var clickedIndex = -1;
      clickedIndex = this.npcontributions.findIndex(np => np.nonProfitId === nonprofit.nonProfitId);
      if (clickedIndex > -1) { //found
        this.npcontributions.splice(clickedIndex, 1);
      }
    }
    //After every add or remove recalculate lineItemsTotal
    this.lineItemsTotal = this.calcTotal();
  }

  //Called after clicking ok/continue in confirm dialog
  continue() {
    //save parameters for appwide access;
    this.seccdonateService.npDonations = this.npcontributions;
    this.seccdonateService.payFactor = this.payFactor;
    this.router.navigate(['contributor/contributions']);
  }

  getContributor(ein : string) {

    this.contributerSubscription = this.seccdonateService.getContributor(ein).subscribe(
      result => {
        let apiresponse : any = result;
        this.seccdonateService.contributor = result;
      },
      err => {
        console.log(err);
      }
    );
  }

  getCounties() {

    this.countiesSubscription = this.seccdonateService.getCounties().subscribe(

      result => {
        this.countiesList = result;
      },
      err => {
      }
    );
  }

  getServicesProvided() {

    this.svcsProvidedSubscription = this.seccdonateService.getServicesProvided().subscribe(

      result => {
        this.svcsProvidedList = result;
      },
      err => {
      }
    );
  }

  searchNonProfit() {

    if(this.spinner != undefined) {
      this.seccdonateService.stopSpinner(this.spinner); // To prevent starting a second spinner below. First already started at ngOnInit().
    }
    this.spinner = this.seccdonateService.startSpinner('spinarea-secc'); //Search needs own spinner when used as standalone (ie outside of ngOnInit())


    console.log('Searching nonProfits');
    if (this.searchParams.locationSearch != 'County') {
      //this.searchParams.locationSelectedCounty = null;
    }

    this.nonProfitSearchSubscription =

      this.seccdonateService.searchNonProfits(this.searchParams).subscribe(

        result => {
          this.seccdonateService.stopSpinner(this.spinner);

          this.nonProfits = result as Array<any>;
          this.length = this.nonProfits.length; //

          //They are all for the same participant Year. Populate in the svc object for receipt display
          this.seccdonateService.participantYear = this.nonProfits[0].participantYear;

        },
        err => {
        }
      );

  }

  /* Calculate 'shopping cart' total for page display.
   * Also calculate total amount payment and save in service obj.
   */
  updateTotal(amount: number, nonProfitId: string) {   //sum of all contributions. 'amount' is received as  string event

    console.log('update total called');
    let idx = this.npcontributions.findIndex(x => x.nonProfitId == nonProfitId);
    this.npcontributions[idx].amount = amount; //works !

    /*ACHTUNG ! updateTotal() is triggered on the onBlur event. If payFactor is changed after entering an amount
    in the box, the annualAmount will reflect the *previous* payFactor since annualAmount gets calculated
    before the payFactor updates. onBlur is perhaps not the based event to trigger updateTotal(). This won't cause
    an issue on the server side because amounts are recalculated there. However it may cause an erroneous amount
    to show on the receipts page. As a quick work around don't use annualAmount to show line item total.
    Recalculate on the receipts page using global variable payFactor.
    */
    this.npcontributions[idx].annualAmount = amount * (this.payFactor); // this is for display only (Receipt page). Recalculated on server side
    this.lineItemsTotal = this.calcTotal();
  }



  //Read list of nonprofitcontribute objects from Service and initilize the
  //contributions panel on the right side of the page. That is assuming previously
  //selected contributions exist.

  updateOrderTotal() {
    this.annualAmount = (this.lineItemsTotal) * (this.payFactor);
    this.seccdonateService.annualAmount = this.annualAmount;
  }


  // If user entered Designations page by clicking Edit Contributions
  // on the Contributions page.
  isEditMode(): boolean {
    return (this.seccdonateService.npDonations.length > 0);
  }


  showConfirmModal() {

    this.updateOrderTotal();

    const dialogRef = this.dialog.open(ConfirmmodalComponent, {
      width: '500px',
      height: 'auto',
      backdropClass: 'backdropBackground'
    });

    dialogRef.componentInstance.payFactor = this.payFactor;
    dialogRef.componentInstance.annualAmount = this.annualAmount;

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {  //From mat-dialog-result : true = continue; false = cancel
        this.continue();
      }
    });
  }


  showNotYouModal() {

    const dialogRef = this.dialog.open(NotyouComponent, {
      width: '500px',
      height: 'auto',
      // data : dialogText,
      //panelClass : 'help-class',
      backdropClass: 'backdropBackground'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.continue();
      }
    });
  }





  //Sum of contributions for all NonProfits
  calcTotal(): number {

    let total: number = 0.00;
    for (var i = 0; i < this.npcontributions.length; i++) {
      if (this.npcontributions[i].amount != undefined) {
        total = (+total) + (+this.npcontributions[i].amount);
      }
    }
    return total;
  }

  // (Re)calculate lineItemsTotal and return value
  getLineItemsTotal(): number {
    if (this.lineItemsTotal == undefined) {
      return 0;
    }
    return this.lineItemsTotal;
  }

  /*
  Number of weeks since the start of the campaign. If campaign hasn't started
  yet, default to 1;
  */
  calcDesignationWeek() {

    var previousDate : Date = new Date();
    this.configSubscription = this.seccdonateService.getConfig('CAMPAIGN_START').subscribe(
      result => {
          var config  = result as any;
          previousDate = new Date(config.value);
          const millisecondsDifference = Date.now() - previousDate.getTime();
          const daysDifference = Math.floor(millisecondsDifference / (1000 * 60 * 60 * 24)); // days elapsed since start of campaign
          if (daysDifference < 0) {
            this.designationWeek = 1;
          } else {
            this.designationWeek = Math.ceil(daysDifference / 7);
          }
      },
      err => {
        console.log(err);
      }
    );
  }

  //Called in Edit mode only. When returning to Designations page, check the boxes for
  //all previously selected nonprofits.
  checkSelectedNonProfits() {

    for (var i = 0; i < this.npcontributions.length; i++) {
      const npCheckBox = document.getElementById(this.npcontributions[i].nonProfitId) as HTMLInputElement
      npCheckBox.checked = true;
    }
  }


  ngOnDestroy() {
    if (this.nonProfitSearchSubscription != undefined) this.nonProfitSearchSubscription.unsubscribe();
    if (this.countiesSubscription != undefined) this.countiesSubscription.unsubscribe(); //svcsProvidedSubscription
    if (this.svcsProvidedSubscription != undefined) this.svcsProvidedSubscription.unsubscribe();
    if (this.contributerSubscription != undefined) this.contributerSubscription.unsubscribe();
    if (this.configSubscription != undefined) this.configSubscription.unsubscribe();
  }
}
