<html xmlns="http://www.w3.org/1999/xhtml">

<head>


    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <title>SECC - Search Nonprofit Organizations</title>
</head>

<body>


    <form name="searchForm" method="post" action="./designations.aspx" id="aspnetForm">



        <div id="wrapper">
            <div id="header">
                <h1><a href="default.aspx">Arizona State Employee Charitable Campaign</a></h1>
                <img id="ctl00_imgHeader" src="assets/img/secc.png" alt="Arizona State Employee Charitable Campaign"
                    style="border-width:0px;">
            </div>
            <div id="nav">

                <ul>
                    <li>
                        <a id="ctl00_LoginView1_LoginStatus1" title="Logout"
                            href="contributor/auth/dologout">LOGOUT</a>
                    </li>
                    <!--<li><a href="feedback.aspx" title="Feedback">FEEDBACK</a></li>//-->
                    <li><a href="assets/doc/Instructions.pdf" title="Instructions" target="_blank">INSTRUCTIONS</a></li>
                    <li><a  href="contributor/welcome"  title="Home">HOME</a></li>
                </ul>

            </div>
            <div id="container">


                <div id="ctl00_mainContent_ModalPopupExtender_foregroundElement"
                    style="display: none; position: fixed;">
                    <div id="ctl00_mainContent_Panel1" class="modalPopup" style="display: none">

                        <div id="ctl00_mainContent_Panel3" style="cursor: auto; background-color: White;
            border: solid 1px Gray; color: Black">

                            <div>
                                <br>
                                <table width="360px" cellpadding="2" cellspacing="2" align="center">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Your annual contribution is
                                                <span id="ctl00_mainContent_lblAmount" style="font-weight:bold;"></span>
                                                <br> <br>
                                                <div id="ctl00_mainContent_dvPrize">

                                                </div>
                                            </td>
                                        </tr>
                                        <tr align="right">
                                            <td align="right" width="100%">
                                                <input type="submit" name="ctl00$mainContent$btnOk" value="Ok"
                                                    id="ctl00_mainContent_btnOk" style="width:75px;">
                                                <input type="submit" name="ctl00$mainContent$CancelButton"
                                                    value="Cancel" id="ctl00_mainContent_CancelButton"
                                                    style="width:75px;">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                            </div>

                        </div>

                    </div>
                </div>
                <!-- <input type="image" name="ctl00$mainContent$Button1" id="ctl00_mainContent_Button1" src="Images/gif.gif" style="border-width:0px;"> -->

                <div id="ctl00_mainContent_ModalPopupExtenderAlert_foregroundElement"
                    style="display: none; position: fixed;">
                    <div id="ctl00_mainContent_Panel2" class="modalPopup" style="display: none">

                        <div id="ctl00_mainContent_Panel4" style="cursor: auto; background-color: White;
            border: solid 1px Gray; color: Black">

                            <div>
                                <br>
                                <table width="350px" cellpadding="2" cellspacing="2" align="center">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Your current annual contribution amount is:
                                                <span id="ctl00_mainContent_lblAmountAlert"
                                                    style="font-weight:bold;"></span><br>
                                                This cannot be less than <strong>$1.00</strong>.<br>
                                                Please modify the contribution amount.
                                            </td>
                                        </tr>
                                        <tr align="right">
                                            <td align="right" width="100%">
                                                <input type="submit" name="ctl00$mainContent$btnClose" value="Close"
                                                    id="ctl00_mainContent_btnClose" style="width:75px;">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                            </div>

                        </div>

                    </div>
                </div>
                <!-- <input type="image" name="ctl00$mainContent$Button2" id="ctl00_mainContent_Button2" src="Images/gif.gif" style="border-width:0px;">-->

                <!-- Deleted stuff. See original code-->

                <div id="grayback">
                    <div id="click1" style="min-height: 300px;">
                        <h4>
                            Nonprofit Organizations</h4>
                        <ul id="orgs">
                            <li id="tabsearch" class="active"><a routerLink="/designations"
                                    id="tabsearcha">Search</a></li>
                        </ul>

                        <br style="clear: both">
                        <h2>
                            Search Nonprofit Organizations</h2>
                        <div id="ctl00_mainContent_searchPanel"

                            style="width:450px;">

                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>By Text:</strong>
                                        </td>
                                        <td colspan="2">
                                            <input name="textSearch" type="text" id="textSearch"  (keyup.enter)="searchNonProfit()"
                                                [(ngModel)]="searchParams.textSearch" style="width:270px;">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>By Service Provided:</strong>
                                        </td>
                                        <td colspan="2">
                                            <select name="serviceSearch" id="serviceSearch" (change)="searchNonProfit()"
                                                [(ngModel)]="searchParams.serviceSearch">
                                                <option></option>
                                                <option *ngFor="let item of svcsProvidedList" value="{{item.code}}">
                                                    {{item.name}}</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>By ST-Code:</strong>
                                        </td>
                                        <td colspan="2"><input name="stCodeSearch" type="text" id="stCodeSearch" (keyup.enter)="searchNonProfit()"
                                                [(ngModel)]="searchParams.stCodeSearch"></td>
                                    </tr>
                                    <tr>
                                        <td><strong>By Location:</strong></td>
                                        <td><select name="locationSearch" id="locationSearch"
                                                [(ngModel)]="searchParams.locationSearch"
                                                (change)="searchNonProfit()">
                                                <option selected="selected" value=""></option>
                                                <option value="County">County</option>
                                                <option value="State">State</option>
                                                <option value="National">National</option>
                                                <option value="International">International</option>

                                            </select></td>
                                        <td align="right">
                                            <input type="submit" name="searchNonpProfitButton_1" value="Search"
                                                (click)="searchNonProfit()"
                                                [hidden]="searchParams.locationSearch =='County'">
                                        </td>
                                    </tr>

                                    <!-- Hide row unless County option selected-->
                                    <tr id="ctl00_mainContent_trCounty"
                                        [hidden]="searchParams.locationSearch !='County'">
                                        <td><strong>County of Interest:</strong></td>
                                        <td>
                                            <select name="countySelect" id="countySelect"
                                                [(ngModel)]="searchParams.locationSelectedCounty">
                                                <option></option>
                                                <option *ngFor="let item of countiesList" value="{{item.code}}">
                                                    {{item.name}}</option>
                                            </select>

                                        </td>
                                        <td align="right">
                                            <input type="submit" name="searchNonpProfitButton_2" value="Search"
                                                (click)="searchNonProfit()">
                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                            <!-- Populate in .ts
                <input type="hidden" name="ctl00$mainContent$hdnParticipantYear" id="ctl00_mainContent_hdnParticipantYear" value="2021">
                <input type="hidden" name="ctl00$mainContent$hdnContributions" id="ctl00_mainContent_hdnContributions" value="0">
                <input type="hidden" name="ctl00$mainContent$hdnMaxContributions" id="ctl00_mainContent_hdnMaxContributions" value="10">
                <br>
                <br>
            -->

                        </div>

                        <div id="search_results">

                            <!--Paginator control-->
                            <mat-paginator [length]="length" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions"
                                (page)="pageEvent = $event" aria-label="Select page" showFirstLastButtons>
                            </mat-paginator>

                            <div id="spinarea-secc"></div>
                            <!--REPEATING ELEMENTS-->
                            <div class="nonprofit"
                                *ngFor="let np of nonProfits | slice:(pageEvent.pageIndex)*(pageEvent.pageSize):(pageEvent.pageIndex)*(pageEvent.pageSize) + pageEvent.pageSize">
                                <div class="header">
                                    <input type="checkbox" id="{{np.nonProfitId}}" (click)="toggleDesignation(np)" />
                                    {{np.nonProfitTitle}}
                                </div>
                                <div class="content">
                                    <img src="{{np.logoLocation}}" style="width:100px;border-width:0px;">
                                    <p><strong>ST-Code:</strong> {{np.stCode}}<br>
                                        <strong>Services Provided:</strong> {{np.svcsProvided}}<br>
                                        <strong>Location:</strong> <span
                                            id="ctl00_mainContent_lvNonProfits_ctrl1_lblLocation">
                                            {{np.location}}</span><br>
                                        <strong>Website:</strong> [<a href="http://{{np.website}}">{{np.website}}</a>]
                                        <strong>Phone:</strong> {{np.phone}}<br>
                                        {{np.narrative}} <strong>Overhead Percentage:</strong> {{np.percentage}}%
                                    </p>
                                </div>
                                <div style="clear: both;"></div>
                            </div>
                            <!--END REPEATING ELEMENTS-->

                            <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                                (page)="pageEvent = $event" aria-label="Select page" showFirstLastButtons>
                            </mat-paginator>


                        </div>
                        <!--End search results-->


                    </div>
                    <div id="click2">
                        <h3>
                            Contributions for
                            {{this.seccdonateService.contributor.firstName + ' ' + this.seccdonateService.contributor.lastName}}<br>
                            (Not
                            {{this.seccdonateService.contributor.firstName + ' ' + this.seccdonateService.contributor.lastName}}? <span class="modalLink"><a (click)="showNotYouModal()">Click here</a>)</span></h3>
                        <p><strong>Instructions</strong></p>
                        <p>Check off each nonprofit you wish to donate to using the lists on the left. When you have
                            checked off all of your nonprofits, enter your contribution in the form below and click
                            Continue. <b>(Do not click continue until you have selected ALL of the nonprofits to which
                                you want to donate.)</b></p>
                        <p>If you do not wish to designate your gift to a specific organization, you may contribute to
                            the "Undesignated" fund. The SECC will distribute the undesignated funds to a charity or
                            charities considering donation patterns as a guide for selection.</p>



                        <p><strong>Enter contribution amounts:</strong> </p>
                        <input [value]="1" name="contributeMode" type="radio" id="contributeMode_Annual"
                            [(ngModel)]="payFactor">
                        Annual
                        <input [value]="26" name="contributeMode" type="radio" id="contributeMode_PayPeriod"
                            [(ngModel)]="payFactor">
                        Pay Period
                        <br> <br>

                        <!-- Array of CONTRIBUTIONS to various orgs-->
                        <app-nonprofitcontribute *ngFor="let item of npcontributions; index as i"
                        (updateTotalInParent)="updateTotal($event,item.nonProfitId)"
                        [nonProfitTitle]="item.nonProfitTitle" [nonProfitId]="item.nonProfitId" [idx]="i"
                        #childNP>

                        </app-nonprofitcontribute>

                        <table style="border: 0px; width: 100%">
                              <tr>
                                <td> </td>
                                <td> </td>
                                <td style="text-align:right;">----------------</td>
                              </tr>
                              <tr>
                                <td>Total</td>
                                <td> </td> <!--value={{lineItemTotal}}-->
                                <td style="text-align:right;"><input name="Total"
                                    style="border:0px; background-color:#CCCCCC; color:Black; text-align:right;" title="Automatically Calculated"
                                    type="text" id="Total" size="8" value="{{getLineItemsTotal() | currency }}" onfocus="this.blur();"></td>
                              </tr>
                        </table>




                        <input type="submit" name="ctl00$mainContent$Continue" (click)="showConfirmModal()" value="Continue"

                            id="ctl00_mainContent_Continue" class="checkbutton">
                    </div>
                    <div style="clear: both">
                    </div>
                </div>

                <div id="ctl00_mainContent_ModalPopupExtender_backgroundElement" class="modalBackground"
                    style="display: none; position: fixed; left: 0px; top: 0px;"></div>
                <div id="ctl00_mainContent_ModalPopupExtenderAlert_backgroundElement" class="modalBackground"
                    style="display: none; position: fixed; left: 0px; top: 0px;"></div>
            </div>
            <div id="footer">
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="47%"><a href="mailto:Linda.Stiles@azdoa.gov" id="ctl00_aDirector"
                                    target="_blank">Linda.Stiles@azdoa.gov</a>
                            </td>
                            <td>
                                <a href="default.aspx">SECC Home</a>
                            </td>
                            <td style="text-align: right; font-weight: bold;">
                                Version: 2.0.0
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </form>


    <div class="ui-dialog ui-widget ui-widget-content ui-corner-all  ui-draggable ui-resizable" tabindex="-1"
        role="dialog" aria-labelledby="ui-dialog-title-ctl00_Timeout1"
        style="display: none; position: absolute; overflow: hidden; z-index: 1000; outline: 0px;">
        <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix" unselectable="on"><span
                class="ui-dialog-title" id="ui-dialog-title-ctl00_Timeout1" unselectable="on">Session Expiring</span><a
                href="#" class="ui-dialog-titlebar-close ui-corner-all" role="button" unselectable="on"><span
                    class="ui-icon ui-icon-closethick" unselectable="on">close</span></a></div><span id="ctl00_Timeout1"
            style="" class="ui-dialog-content ui-widget-content">
            <p>
                <span class="ui-icon ui-icon-alert" style="float: left; margin: 1px 10px 20px 0;">
                </span>Your session will expire in.
            </p>
            <span id="tscCounter"></span>&nbsp;seconds
            <p>
                Click <b>OK</b> to continue your session.</p>
        </span>
        <div class="ui-resizable-handle ui-resizable-n" unselectable="on"></div>
        <div class="ui-resizable-handle ui-resizable-e" unselectable="on"></div>
        <div class="ui-resizable-handle ui-resizable-s" unselectable="on"></div>
        <div class="ui-resizable-handle ui-resizable-w" unselectable="on"></div>
        <div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se ui-icon-grip-diagonal-se"
            unselectable="on" style="z-index: 1001;"></div>
        <div class="ui-resizable-handle ui-resizable-sw" unselectable="on" style="z-index: 1002;"></div>
        <div class="ui-resizable-handle ui-resizable-ne" unselectable="on" style="z-index: 1003;"></div>
        <div class="ui-resizable-handle ui-resizable-nw" unselectable="on" style="z-index: 1004;"></div>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix"><button type="button"
                class="ui-state-default ui-corner-all">Ok</button></div>
    </div>
</body>

</html>
