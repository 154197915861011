<!-- Contirbution amount validation-->

<!-- VALIDATION ERROR-->
<div id="amterror" *ngIf="seccdonateService.annualAmount < 1">
  Your total annual contribution is: <b>{{seccdonateService.annualAmount | currency}}</b> <p>
  Please enter an amount <strong>$1.00</strong> or greater.

  <table>
    <tr>
      <td><span mat-dialog-actions>
          <button mat-button [mat-dialog-close]="false">Close</button>
        </span></td>
    </tr>
  </table>
</div>






<!----------NO VALIDATION ERROR----------->

<div *ngIf="seccdonateService.annualAmount >= 1">

  <div id="payperiod" *ngIf="payFactor==26">
    Your annual contribution is $ {{annualAmount}} <p>
      Click Cancel if you would like to increase your donation.<br>
      Click Continue to keep your current donation.
  </div>


  <div id="annual" *ngIf="payFactor==1">
    Your annual contribution is $ {{annualAmount}} <p>
      If you make a donation of at least $52 annually, you will receive at least 1 entry into our prize drawing.<br>
      Click Cancel if you would like to increase your donation.<br>
      Click Continue to keep your current donation.
  </div>

  <table>
    <tr>
      <td><span mat-dialog-actions>
          <button mat-button [mat-dialog-close]="false">Cancel</button>
        </span></td>
      <td><span mat-dialog-actions>
          <button mat-raised-button [mat-dialog-close]="true">Continue</button>
        </span></td>
    </tr>
  </table>
</div>
